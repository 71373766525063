<template>
  <section class="get-protection">
    <div class="container">
      <h3 class="get-protection__section-title section-title">Как получить охрану?</h3>

      <div class="get-protection__content-wrapper">
        <form class="get-protection__form">
          <label class="get-protection__label">
            <input class="get-protection__input input" type="text" placeholder="Ваше имя">
          </label>

          <div class="get-protection__label-wrapper">
            <label class="get-protection__label">
              <input class="get-protection__input input" type="tel" placeholder="Ваш номер">
            </label>

            <label class="get-protection__label">
              <input class="get-protection__input input" type="email" placeholder="Email">
            </label>
          </div>

          <label class="get-protection__label">
            <input class="get-protection__input input" type="text" placeholder="Ваш вопрос">
          </label>

          <button
            class="get-protection__submit-btn btn btn--primary"
            type="submit"
          >
            Задать вопрос
          </button>
        </form>

        <div class="get-protection__contacts">
          <p class="get-protection__contacts-title">
            Оставьте вашу заявку и получите бесплатную консультацию от наших специалистов.
          </p>

          <ul class="get-protection__contacts-list">
            <li class="get-protection__contacts-item">
              <img src="@/assets/icons/phone-icon.svg" alt="Phone">

              <a
                class="get-protection__contacts-link"
                href="tel:+380999999999"
              >
                +38 (099) 999 99 99
              </a>
            </li>
            
            <li class="get-protection__contacts-item">
              <img src="@/assets/icons/email-icon.svg" alt="Email">

              <a
                class="get-protection__contacts-link"
                href="mailto:hello@vimpel.com"
              >
                hello@vimpel.com
              </a>
              <a href="#" ></a>
            </li>
            
            <li class="get-protection__contacts-item">
              <img src="@/assets/icons/geo-point-icon.svg" alt="Address">

              <a href="#" class="get-protection__contacts-link">
                г. Запорожье, улица Комарова 2
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GetProtection',
}
</script>