<template>
  <section class="intro-info">
    <div class="container">

      <HeroTitle :info="titleInfo" />

      <button class="intro-info__btn btn">Получить консультацию</button>

      <div class="intro-info__statistics">
        <ul class="intro-info__statistics-list">
          <li
            v-for="(item, index) in statisticsInfo"
            :key="index"
            class="intro-info__statistics-item"
          >
            <span class="intro-info__statistics-number">{{ item.number }}</span>
            <p class="intro-info__statistics-text">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import HeroTitle from '@/components/HeroTitle'

export default {
  name: 'IntroInfo',
  components: {HeroTitle},
  data: () => ({
    statisticsInfo: [
      {
        number: 12,
        text: '12 городов и областных регионов в зоне предоставления услуг'
      },
      {
        number: 450,
        text: 'Свыше 450 коммерческих и частных объектов под физической охраной'
      },
      {
        number: 6,
        text: 'Более 6 лет на рынке охранных услуг Украины'
      },
    ],
    titleInfo: {
      image: require('@/assets/images/logo-background.svg'),
      title: 'Первые на рынке охранных услуг с момента его основания',
      subtitle: 'Получите бесплатную консультацию от наших специалистов'
    }
  })
}
</script>