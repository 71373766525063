<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__copyright-wrapper">
        <p class="footer__copyright">
          © ТОВ “УСБ Вымпел”
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>