<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Layouts/Header'
import Footer from '@/components/Layouts/Footer'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
}
</script>