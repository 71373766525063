<template>
  <div class="carousel__wrapper">
    <VueSlickCarousel
      ref="carousel"
      v-bind="settings"
      class="carousel__news-carousel"
    >
      <div
        v-for="item in news"
        :key="item.id"
        class="carousel__item"
      >

        <div class="carousel__thumbnail-wrapper">
          <a
            :href="item.link"
            class="carousel__item-link">
            <img
              class="carousel__thumbnail"
              :src="item.thumbnail"
              :alt="item.title"
            >
          </a>
        </div>

        <span class="carousel__pub-date">{{ item.publicationDate }}</span>

        <h5 class="carousel__item-title">{{ item.title }}</h5>
      </div>
    </VueSlickCarousel>

    <div class="carousel__arrows-wrapper">
      <div class="carousel__arrow-container">
        <button
          @click="showPrev"
          class="carousel__arrow carousel__arrow--prev"
        >
          <img src="~@/assets/icons/arrow-prev.svg" alt="Назад">
        </button>

        <span class="carousel__controls-text">Назад</span>
      </div>

      <div class="carousel__arrow-container">
        <span class="carousel__controls-text">Дальше</span>

        <button
          @click="showNext"
          class="carousel__arrow carousel__arrow--next"
        >
          <img src="~@/assets/icons/arrow-next.svg" alt="Дальше">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel
  },
  props: {
    news: {
      type: Array,
      default: () => ([]),
    }
  },
  data: () => ({
    settings: {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false
    }
  }),
  methods: {
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      this.$refs.carousel.prev()
    }
  }
}
</script>