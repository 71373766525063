<template>
  <nav class="header__navigation">
    <div class="header__logo-wrapper">
      <a href="/" class="header__logo-link">
        <img src="@/assets/images/logo-nav.svg" alt="Ukrainska Sluzhba Bezpeky">
      </a>
    </div>

    <ul class="header__navigation-list">
      <li class="header__navigation-item">
        <a href="#aboutUs" class="header__navigation-link">О нас</a>
      </li>

      <li class="header__navigation-item">
        <p class="header__navigation-link">Физическая охрана</p>

        <span class="header__dropdown-icon-wrapper">
          <img
            class="header__dropdown-icon"
            src="@/assets/icons/dropdown-icon.svg"
            alt="Dropdown icon"
          >
        </span>
      </li>

      <li class="header__navigation-item">
        <p class="header__navigation-link">Охрана домов</p>

        <span class="header__dropdown-icon-wrapper">
          <img
            class="header__dropdown-icon"
            src="@/assets/icons/dropdown-icon.svg"
            alt="Dropdown icon"
          >
        </span>
      </li>

      <li class="header__navigation-item">
        <p class="header__navigation-link">Технические возможности</p>

        <span class="header__dropdown-icon-wrapper">
          <img
            class="header__dropdown-icon"
            src="@/assets/icons/dropdown-icon.svg"
            alt="Dropdown icon"
          >
        </span>
      </li>
    </ul>

    <button class="header__contacts-btn btn btn--primary">Контакты</button>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
}
</script>

<style scoped>

</style>