<template>
  <section class="news">
    <div class="container">
      <h3 class="news__section-title section-title">Новости</h3>

      <div class="news__content-wrapper">
        <Carousel
          :news="newsList"
          class="news__carousel"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Carousel from '@/components/Layouts/Carousel'

export default {
  name: 'News',
  components: {
    Carousel
  },
  data: () => ({
    newsList: [
      {
        id: 1,
        link: '#',
        thumbnail: require('@/assets/images/news/image-01.png'),
        publicationDate: '12 авг 2021',
        title: 'Собака отдыхает когда работает УСБ'
      },
      {
        id: 2,
        link: '#',
        thumbnail: require('@/assets/images/news/image-02.png'),
        publicationDate: '12 авг 2021',
        title: 'Предотвращение крупной кражи в АТБ'
      },
      {
        id: 3,
        link: '#',
        thumbnail: require('@/assets/images/news/image-03.png'),
        publicationDate: '12 авг 2021',
        title: 'Завершена разработка новой системы '
      },
      {
        id: 4,
        link: '#',
        thumbnail: require('@/assets/images/news/image-04.png'),
        publicationDate: '12 авг 2021',
        title: 'Как это охрана 24/7?'
      },
      {
        id: 5,
        link: '#',
        thumbnail: require('@/assets/images/news/image-01.png'),
        publicationDate: '12 авг 2021',
        title: 'Собака отдыхает когда работает УСБ'
      },
      {
        id: 6,
        link: '#',
        thumbnail: require('@/assets/images/news/image-02.png'),
        publicationDate: '12 авг 2021',
        title: 'Предотвращение крупной кражи в АТБ'
      },
    ]
  })
}
</script>