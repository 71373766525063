<template>
  <section class="about-us">
    <div class="container">
      <div class="about-us__content-wrapper">
        <div>
          <h3 class="about-us__section-title section-title">О нас</h3>
          <p class="about-us__text">
            Наше личная преимущество - это опыт бывших бойцов подразделений спецназначения МВД и СБУ Украины, работающих
            в
            нашей компании, и расширенный комплекс индивидуальных мер охраны Ваших объектов, как традиционных , так и
            инновационных (охрана с помощью дронов, использование спецсредств, внедрение аналитических и детективных
            мер),
            которые позволяют выявлять угрозы злонамеренных действий и задерживать правонарушителей к моменту совершения
            ими противоправных действий, связанных с посягательством на нормальную и безопасную деятельность Ваших
            объектов.
          </p>
        </div>

        <img class="about-us__bg-img" src="~@/assets/images/about-us-bg.png" alt="О нас">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs',
}
</script>