<template>
  <section class="common-blocks">
    <div class="container">
      <h3 class="common-blocks__section-title section-title">{{ title }}</h3>

      <ul class="common-blocks__services-list">
        <li
          v-for="(item, index) in blocksInfo"
          :key="index"
          class="common-blocks__services-item"
        >
          <img :src="item.img" alt="" class="common-blocks__card-img">

          <div class="common-blocks__card-title-wrapper">
            <a class="common-blocks__card-link" href="#">
              <h4 class="common-blocks__card-title">{{ item.title }}</h4>

              <img src="~@/assets/icons/arrow-right.svg" alt="Arrow right">
            </a>
          </div>

          <p class="common-blocks__card-text">{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CommonBlocksCards',
  props: {
    title: {
      type: String,
      default: ""
    },
    blocksInfo: {
      type: Array,
      default: () => ([{}])
    }
  }
}
</script>