<template>
  <main>
    <IntroInfo />
    <AboutUs />
    <CommonBlocksCards
      title="Наши услуги"
      :blocksInfo="servicesItems"
    />
    <GetProtection />
    <News />
  </main>
</template>

<script>
import IntroInfo from '@/components/Sections/IntroInfo'
import AboutUs from '@/components/Sections/AboutUs'
import CommonBlocksCards from '@/components/CommonBlocksCards'
import GetProtection from '@/components/Sections/GetProtection'
import News from '@/components/Sections/News'

export default {
  name: 'Main',
  components: {
    CommonBlocksCards,
    News,
    GetProtection,
    AboutUs,
    IntroInfo
  },
  data: () => ({
    servicesItems: [
      {
        img: require('@/assets/images/our-services/image-01.png'),
        title: 'Физическая охрана',
        description: 'Обеспечение безопасности и сохранности недвижимого имущества на охраняемых стационарных объектах',
      },
      {
        img: require('@/assets/images/our-services/image-02.png'),
        title: 'Охрана домов',
        description: 'Мы разработаем и внедрим индивидуальную систему безопасности Вашего  дома, которая состоит из ' +
          'профессиональных защитных средств и мероприятий',
      },
      {
        img: require('@/assets/images/our-services/image-03.png'),
        title: 'Технические снабжение',
        description: 'Наша компания занимается монтажом систем безопасности в любых помещениях, например, ' +
          'в квартире, частном доме, в офисе и любом другом здании.',
      },
    ],
  })
}
</script>