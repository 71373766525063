<template>
  <div class="hero-title__wrapper">
    <div class="hero-title__logo-bg-wrapper">
      <img :src="info.image" alt="USB">
    </div>

    <div class="hero-title__text-content">
      <h1 class="hero-title__main-title main-title">{{ info.title }}</h1>

      <p class="hero-title__main-subtitle">{{ info.subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroTitle',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>